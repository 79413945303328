import moment from 'moment';

import appInfo from 'js/lib/appInfo';
import { get as getCookie, set as setCookie } from 'js/lib/cookie';
import { get as getUser, isAuthenticatedUser } from 'js/lib/user';

import {
  API_BEFORE_SEND,
  API_ERROR,
  API_IN_PROGRESS,
  API_SUCCESS,
} from '@coursera/coursera-ui/lib/constants/sharedConstants';
import type { ApiStatus } from '@coursera/coursera-ui/lib/constants/sharedConstants';

import { EXCLUDED_APP_NAMES, EXCLUDED_PATHS, PROFILE_CONSENT_COOKIE_NAME } from 'bundles/user-consent/constants';
import type { ProfileConsentCookieConsentNamesTypes } from 'bundles/user-consent/types';

import _t from 'i18n!nls/user-consent';

export const isExcludedPage = (pathname?: string) => {
  const appName = appInfo.get().appName || '';

  return pathname?.match(EXCLUDED_PATHS) !== null || EXCLUDED_APP_NAMES.includes(appName);
};

export const isMissingCookieConsent = (consentName: ProfileConsentCookieConsentNamesTypes) => {
  try {
    const userId = getUser().id;
    const profileConsentCacheCookie = getCookie(PROFILE_CONSENT_COOKIE_NAME);
    if (profileConsentCacheCookie) {
      const profileConsentCacheValue = JSON.parse(atob(profileConsentCacheCookie));

      return !(profileConsentCacheValue[userId][consentName] === false);
    } else {
      return true;
    }
  } catch (err) {
    return true;
  }
};

export const setProfileConsentCookie = (consents: Partial<Record<ProfileConsentCookieConsentNamesTypes, boolean>>) => {
  if (isAuthenticatedUser()) {
    const userId = getUser().id;
    const profileConsentCacheCookie = getCookie(PROFILE_CONSENT_COOKIE_NAME);
    let newCookieValue = consents;
    if (profileConsentCacheCookie) {
      const profileConsentCacheValue = JSON.parse(atob(profileConsentCacheCookie));
      newCookieValue = { ...profileConsentCacheValue[userId], ...consents };
    }

    setCookie(PROFILE_CONSENT_COOKIE_NAME, btoa(JSON.stringify({ [userId]: newCookieValue })), {
      days: 1,
    });
  }
};

export const getButtonLabel = (status: ApiStatus) => {
  switch (status) {
    case API_BEFORE_SEND:
    default:
      return _t('Continue');
    case API_IN_PROGRESS:
      return _t('Processing...');
    case API_SUCCESS:
      return _t('Success');
    case API_ERROR:
      return _t('Failed');
  }
};

export const isUserEligibleForTOUBanner = (joinDate: string, termsUpdateDate: string): boolean => {
  const userJoinDate = moment(joinDate);
  const termsUpdate = moment(termsUpdateDate);
  const termsUpdatePlus90Days = termsUpdate.clone().add(90, 'days');

  // Compare the dates
  // Returns true if the user joined before the terms update and the terms update is within 90 days

  return userJoinDate.isBefore(termsUpdate) && moment().isBefore(termsUpdatePlus90Days);
};

export default {
  isExcludedPage,
  isMissingCookieConsent,
  setProfileConsentCookie,
};
